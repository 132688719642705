import { AchieveLink } from 'components/AchieveLink'
import { Typography } from 'components/Contentful'
import Section from 'components/Section'

export default function SimpleFooter({ footerNavigation, pageDisclosure, copyrightMessage }) {
  const fullList = footerNavigation?.flatMap((i) => i.menuItems) ?? []

  const Menu = () => {
    const list = []
    for (let i = 0; i < fullList.length; i++) {
      const element = fullList[i]
      if (i != 0 && i < fullList.length && i % 3 !== 0) {
        list.push(<div className="col-span-1">•</div>)
      }
      list.push(
        <div className="col-span-3">
          <AchieveLink href={element.url} className="text-blue-800">
            <Typography
              content={element.label}
              variant="bodyXs"
              fontWeight="bold"
            />
          </AchieveLink>
        </div>
      )
    }
    return list
  }

  const currentYear = new Date().getFullYear()
  return (
    <Section className="w-full py-4 bg-gray-100">
      <div className="flex flex-col gap-2">
        <div class="container mx-auto grid grid-cols-6 xl:grid-cols-12 justify-center border-solid border-0 border-b-2 border-b-gray-300 pb-4">
          <div class="col-start-1 col-span-6 sm:col-start-2 sm:col-span-4 xl:col-start-5 xl:col-span-4">
            <div class="container mx-auto grid grid-cols-11 gap-4 text-sm font-semibold text-blue-800 text-center place-items-center">
              <Menu />
            </div>
          </div>
        </div>
        <div className="text-justify">{pageDisclosure}</div>
        <div className="text-center">
          <Typography
            content={`© ${currentYear} ${copyrightMessage}`}
            variant="bodyXs"
            fontWeight="regular"
          />
        </div>
      </div>
    </Section>
  )
}
